import './common'
import axios from 'axios'

export default {
    getClients: (params) => axios.get('/clients', { params }),
    getPaginatedClients: (params) => axios.get('/paginated-clients', { params }),
    getClient: (id) => axios.get(`/clients/${id}`),
    getClientUnits: (id) => axios.get(`/clients/${id}/units`),
    getClientAlerts: (id) => axios.get(`/clients/${id}/alerts`),
    createClient: (data) => axios.post('/clients', data),
    updateClient: (id, client) => axios.put(`/clients/${id}`, client),
    upsertClientMandate: (id, mandate) => axios.post(`/clients/${id}/mandates`, mandate),
    updateClientSubscriptions: (id, subscriptions) => axios.put(`/clients/${id}/subscriptions`, subscriptions),
    deleteClient: (id) => axios.delete(`/clients/${id}`),
    reactivateClient: (id) => axios.post(`/clients/${id}/reactivate`),

    checkMail: (mail) => axios.get('/check-mail', { params: { mail } }),
    addSubscriptionToClient: (clientID, productID) => axios.post(`/clients/${clientID}/subscriptions`, { ProductID: productID }),
    mergeClients: (data) => axios.post('/merge-clients', data)
}
