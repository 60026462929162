import './common'
import axios from 'axios'

export default {
    getSales: (params) => axios.get('/sales', { params }),
    getPaginatedSales: (params) => axios.get('/paginated-sales', { params }),
    getSale: (id) => axios.get(`/sales/${id}`),
    getSaleBill: (id) => axios.get(`/front/sales/${id}/bill`),
    getPendingSubscription: (id, params) => axios.get(`sales/${id}/pending-subscription`, { params })
}
