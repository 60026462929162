import {
    familyType,
    familyTypeIcon,
    familyTypes,
    groupExitModes,
    pricingFilters,
    productFiltersTypes,
    renewModeBalanceTypes,
    renewModeValiditytTypes,
    selectablesPricingsFilters
} from '../ui/products'
import helpers from '../helpers/products'

export default {
    data: () => ({
        familyTypes,
        groupExitModes,
        pricingFilters,
        productFiltersTypes,
        renewModeBalanceTypes, 
        renewModeValiditytTypes,
        selectablesPricingsFilters
    }),
    methods: {
        ...helpers,
        familyType,
        familyTypeIcon,
    }
}