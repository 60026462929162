import Decimal from "decimal.js"
import { isNumeric, isPositive, isPositiveOrZero, isInteger, isValidEmail } from "./utils"
import moment from "moment"

export default {
    requiredRule: v => !!v || 'Ce champ est requis',
    isRequiredAndNotEmptyRule: v => !!v && (!Array.isArray(v) || v.length > 0) || 'Ce champ est requis',
    isIntegerRule: v => isInteger(v) || 'Cette valeur doit être entière',
    isPositiveRule: v => isPositive(v) || 'Cette valeur doit être supérieure à zéro',
    isIntegerOrNullRule: v => (!v || isInteger(v)) || 'Cette valeur doit être entière',
    isPositiveOrNullRule: v => (!v || isPositive(v)) || 'Cette valeur doit être supérieure à zéro',
    isPositiveOrZeroRule: v => isPositiveOrZero(v) || 'Cette valeur doit être supérieure ou égale à zéro',
    isNumericRule: v => isNumeric(v) || 'Cette valeur doit être numérique',
    isNumericOrNullRule: v => (!v || isNumeric(v)) || 'Cette valeur doit être numérique',
    isGreatherThanRule: c => v => parseFloat(v) > c || `Cette valeur doit être supérieure à ${new Decimal(c).toFixed(2)}`,
    isGreatherOrEqualRule: c => (v, decimals) => parseFloat(v) >= c || `Cette valeur doit être supérieure ou égale à ${new Decimal(c).toFixed(decimals || 2)}`,
    isLessThanRule: c => v => parseFloat(v) < c || `Cette valeur doit être inférieure à ${new Decimal(c).toFixed(2)}`,
    isLessOrEqualRule: c => v => parseFloat(v) <= c || `Cette valeur doit être inférieure ou égale à ${new Decimal(c).toFixed(2)}`,
    isEmailRule: v => isValidEmail(v) || "L'email doit être valide",
    isEmailOrNullRule: v => (!v || isValidEmail(v)) || "L'email doit être valide",
    isTimeRule: v => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "Cette valeur doit être une heure valide (HH:mm)",
    isDateFormatRule: f => v => moment(v, f, true).isValid() || `Cette valeur doit être une date valide (${f})`,
    containsUppercaseCharRule: v => /[A-Z]/.test(v) || 'Cette valeur doit contenir au moins une lettre majuscule',
    containsLowercaseCharRule: v => /[a-z]/.test(v) || 'Cette valeur doit contenir au moins une lettre minuscule',
    containsNumberRule: v => /[0-9]/.test(v) || 'Cette valeur doit contenir au moins un chiffre',
    containsSpecialCharRule: v => /[^a-zA-Z0-9]/.test(v) || 'Cette valeur doit contenir au moins un caractère spécial',
    isStringLongerThanRule: c => v => v.length >= c || `Cette valeur doit contenir au moins ${c} caractères`,
}
