import store from '../store/store.js'

function hasRights (...rights) {
    const user = store.getters.authUser
    return (user && user.Permissions && rights.every((el) => user.Permissions.includes(el)))
}

export function isAuthenticated () {
    return !!store.getters.authUser
}

export function setForbidden (val) {
    store.commit('set_forbidden', val)
}

export function checkRights (routeName) {
    let allowed = true
    switch (routeName) {
        // Planning
        case 'planning-index':
            allowed = hasRights('admin:planning')
            break
        // CTM
        case 'turnstiles-status':
            allowed = hasRights('admin:ctm')
            break
        case 'turnstiles-planning':
            allowed = hasRights('admin:ctm:planning')
            break
        case 'turnstiles-area-configuration':
        case 'planning-area-details':
            allowed = hasRights('admin:ctm:area')
            break
        case 'timeslots-templates':
            allowed = hasRights('admin:ctm:planning:manage')
            break
        case 'turnstiles-weeks':
            allowed = hasRights('admin:ctm:type-week')
            break
        case 'turnstiles-scan':
            allowed = hasRights('admin:ctm:scan')
            break
        case 'turnstiles-history':
            allowed = hasRights('admin:ctm:passings')
            break
        case 'turnstiles-penalties':
            allowed = hasRights('admin:ctm:penalty')
            break
        // CLients
        case 'list':
        case 'clubs':
        case 'planning-clubs':
        case 'clubs-show':
        case 'clients-show':
        case 'clubs-new':
            allowed = hasRights('admin:clients')
            break

        // Business
        case 'business-exports-financial':
        case 'business-families-daily':
        case 'business-families-financial':
        case 'business-exports-daily':
            allowed = hasRights('admin:business:export')
            break
        case 'business-gift-manager':
            allowed = hasRights('admin:business:voucher')
            break
        case 'business-gift-manager-plancha':
            allowed = hasRights('admin:business:voucher-sheet')
            break
        case 'business-gift-manager-plancha-create':
            allowed = hasRights('admin:business:voucher-sheet:manage')
            break
        case 'business-discounts-manager-templates':
        case 'business-gift-manager-template-edit':
            allowed = hasRights('admin:business:discount:template')
            break
        case 'business-discounts-manager-show':
            allowed = hasRights('admin:business:discount:code')
            break
        // SPA
        case 'spa-planning':
            allowed = hasRights('admin:spa')
            break
        case 'spa-personnels':
        case 'spa-personnels-edit':
        case 'spa-resources':
        case 'spa-resources-edit':
        case 'spa-activities':
        case 'spa-activities-edit':
        case 'spa-products':
        case 'spa-products-edit':
        case 'spa-products-stocks':
            allowed = hasRights('admin:spa:manage')
            break
        // Stats
        case 'stats-index':
        case 'stats-dashboard-layout':
        case 'stats-dashboard-activities':
        case 'stats-dashboard-attendance':
        case 'stats-dashboard-passings':
        case 'stats-dashboard-sales':
        case 'stats-activity':
        case 'stats-activity-compare':
        case 'stats-passings':
        case 'stats-sales':
        case 'stats-predictions':
            allowed = hasRights('admin:stats')
            break
        // Periodic
        case 'sessions':
        case 'activities-sessions-show':
        case 'registrations':
        case 'phases':
            allowed = hasRights('admin:periodic')
            break
        case 'activities-sessions-new':
        case 'phases-new':
        case 'phases-show':
            allowed = hasRights('admin:periodic:manage')
            break
        case 'registrations-new':
        case 'registrations-edit':
        case 'cashregister-new':
            allowed = hasRights('admin:periodic:booking')
            break
        // Lockers
        case 'lockers-dashboard':
        case 'lockers-bookings':
        case 'lockers-new-booking-export-mode':
        case 'lockers-new-booking-guided-mode':
        case 'lockers-manager':
        case 'lockers-establishment-details':
        case 'lockers-area-details':
        case 'lockers-block-details':
        case 'lockers-locker-details':
        case 'lockers-incidents':
        case 'lockers-gateways':
            allowed = hasRights('admin:lockers')
            break
        // Kiosks
        case 'kiosks-index':
            allowed = hasRights('admin:kiosks')
            break
        // Quotations
        case 'quotation-accounts':
        case 'quotation-accounts-show':
        case 'quotation-requests':
        case 'quotation-requests-show':
        case 'quotation-quotations':
        case 'quotations-show':
        case 'quotation-orders':
        case 'quotation-orders-show':
            allowed = hasRights('admin:quotations')
            break
        case 'quotation-quotations-new':
        case 'quotation-settings':
            allowed = hasRights('admin:quotations:manage')
            break
        // Params
        case 'setup-main':
            allowed = hasRights('admin:params', 'admin:params:settings')
            break
        case 'setup-establishments':
        case 'setup-establishments-new':
        case 'setup-establishments-edit':
        case 'setup-establishments-edit-map':
            allowed = hasRights('admin:params', 'admin:params:establishments')
            break
        case 'setup-mailer':
            allowed = hasRights('admin:params', 'admin:params:mail')
            break
        case 'setup-smser':
            allowed = hasRights('admin:params', 'admin:params:sms')
            break
        case 'setup-templates':
        case 'setup-templates-new':
        case 'setup-templates-edit':
            allowed = hasRights('admin:params', 'admin:params:template')
            break
        case 'setup-documents':
            allowed = hasRights('admin:params', 'admin:params:document')
            break
        case 'setup-pricings':
            allowed = hasRights('admin:params', 'admin:params:pricings')
            break
        case 'setup-planning':
            allowed = hasRights('admin:params', 'admin:params:planning')
            break
        case 'setup-lockers-global':
        case 'setup-lockers-params':
        case 'setup-lockers-booking':
            allowed = hasRights('admin:params', 'admin:params:lockers')
            break
        case 'setup-spa':
        case 'setup-universe-edit':
        case 'setup-universe-params':
            allowed = hasRights('admin:params', 'admin:params:spa')
            break
        case 'setup-turnstiles':
            allowed = hasRights('admin:params', 'admin:params:ctm')
            break
        case 'setup-areas':
            allowed = hasRights('admin:params', 'admin:params:area')
            break
        case 'setup-stats':
            allowed = hasRights('admin:params', 'admin:params:stats')
            break
        case 'setup-business':
            allowed = hasRights('admin:params', 'admin:params:business')
            break
        case 'setup-front-office-general':
        case 'setup-front-office-payments':
            allowed = hasRights('admin:params', 'admin:params:front')
            break

        // products
        case 'products-index':
        case 'products-form':
        case 'stocks':
            allowed = hasRights('admin:products')
            break
        case 'prices-families':
            allowed = hasRights('admin:products:family-price')
            break
        case 'suppliers':
        case 'supplierDetails':
            allowed = hasRights('admin:products:supplier')
            break
        case 'periods':
            allowed = hasRights('admin:products:period')
            break
    }

    return allowed
}
