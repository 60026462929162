import Consts from '../consts'
import { fileDocumentTypes } from "./files";
import { turnstilesStates } from "./access-control";

const productFiltersTypes = [
    { text: 'Crédit', value: "credit" },
    { text: 'Abonnement', value: "subscription" },
    { text: 'Pass quantité', value: "passQ" },
    { text: 'Pass horaire', value: "passH" },
    { text: 'QR code', value: "QRCode" },
    { text: 'Badge', value: "card" },
    { text: 'Pack', value: "pack" },
]

const renewModeValiditytTypes = [
    { text: "Ne pas ajouter le temps restant à la durée de validité", value: Consts.RENEW_OPTIONS_USE_SUBSCRIPTION_VALIDITY_ONLY },
    { text: "Ajouter le temps restant à la durée de validité", value: Consts.RENEW_OPTIONS_ADD_REMAINING_VALIDITY },
]

const renewModeBalanceTypes = [
    { text: "Toujours conserver le solde existant", value: Consts.RENEW_OPTIONS_KEEP_BALANCE_ALWAYS },
    { text: "Conserver le solde existant si l'abonnement n'est pas expiré", value: Consts.RENEW_OPTIONS_KEEP_BALANCE_IF_NOT_EXPIRED },
    { text: "Ne jamais conserver le solde existant", value: Consts.RENEW_OPTIONS_KEEP_BALANCE_NEVER },
]

const groupExitModes = [
    { text: "Jamais", value: Consts.GROUP_EXIT_AUTO_NEVER },
    { text: "Seulement pour les passages d'entrée de groupe créés depuis la caisse", value: Consts.GROUP_EXIT_AUTO_MANUAL_ONLY },
    { text: "Pour tous les passages d'entrée de groupe", value: Consts.GROUP_EXIT_AUTO_ALWAYS },
]

const familyTypes = [
    { text: "Caisse", value: Consts.FAMILIES_TYPES.CASHREGISTER, icon: 'mdi-cash-register' },
    { text: "Borne", value: Consts.FAMILIES_TYPES.KIOSK, icon: 'mdi-monitor' },
    { text: "Web", value: Consts.FAMILIES_TYPES.WEB, icon: 'mdi-web' },
    { text: "Contrôle d'accès", value: Consts.FAMILIES_TYPES.ACCESS_CONTROL, icon: "mdi-turnstile" },
    { text: "Statistiques", value: Consts.FAMILIES_TYPES.STATS, icon: 'mdi-finance' },
    { text: "Exports financiers", value: Consts.FAMILIES_TYPES.EXPORT, icon: 'mdi-file-table-box-multiple-outline' },
    { text: "Exports journaliers", value: Consts.FAMILIES_TYPES.DAILY, icon: 'mdi-calendar-cursor' },
    { text: "CE", value: Consts.FAMILIES_TYPES.QUOTATION, icon: 'mdi-file-sign' },
]

const pricingFilters = [
    { text: 'Tous les types de clients', value: Consts.ALLOW_ACCOUNTS_MODE.ALL },
    { text: 'Personnes physiques uniquement', value: Consts.ALLOW_ACCOUNTS_MODE.PERSON },
    { text: 'Personnes morales uniquement', value: Consts.ALLOW_ACCOUNTS_MODE.ORG },
]

const selectablesPricingsFilters = [
    { text: 'Tous les tarifs', value: -1 },
    { text: 'Tarifs pour tous les types de clients', value: Consts.ALLOW_ACCOUNTS_MODE.ALL },
    { text: 'Tarifs pour les personnes physiques uniquement', value: Consts.ALLOW_ACCOUNTS_MODE.PERSON },
    { text: 'Tarifs pour les personnes morales uniquement', value: Consts.ALLOW_ACCOUNTS_MODE.ORG },
]

export function familyType(value) {
    return familyTypes.find(family => family.value === value)
}

export function familyTypeIcon(value) {
    if (value) {
        return familyTypes.find(family => family.value === value).icon
    } else {
        return 'mdi-cash-register'
    }
}


export { familyTypes, groupExitModes, pricingFilters, productFiltersTypes, renewModeBalanceTypes, renewModeValiditytTypes, selectablesPricingsFilters }