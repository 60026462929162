<template>
    <v-autocomplete
        ref="clientSearchField"
        :items="clientSearch.items"
        v-model="val"
        item-value="ID"
        :item-text="clientFullname"
        :return-object="returnObject"
        :hide-details="hideDetails"

        :disabled="disabled"
        :clearable="clearable"
        :search-input.sync="clientSearch.search"
        :loading="clientSearch.loading"
        autocomplete="off"
        no-data-text="Aucun client"
        :multiple="multiple"
        :chips="chips"
        :deletable-chips="deletableChips"

        :prepend-icon="icon"
        :prepend-inner-icon="prependInnerIcon"
        :label="fieldLabel"

        :dense="dense"
        :filled="filled"
        :solo="solo"
        no-filter
        :attach="attach"
        :rules="rules || (mandatory ? [requiredRule] : [])"

        :style="autocompleteStyle"
        :readonly="readonly"

        @change="onChange"
        @click:clear="onClear"
    >
        <template v-if="showDetails" v-slot:selection="{item}">
            <v-list-item-content>
                <v-list-item-title>{{
                    item.Firstname + ' ' + item.Lastname
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.BirthDate">{{
                    item.BirthDate
                        ? formatDate(item.BirthDate, 'DD/MM/YYYY')
                        : ''
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.Contact.Email">{{
                    item.Contact.Email ? item.Contact.Email : ''
                }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-if="showDetails" v-slot:item="{item}">
            <v-list-item-content>
                <v-list-item-title>{{
                    item.Firstname + ' ' + item.Lastname
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.BirthDate">{{
                    item.BirthDate
                        ? formatDate(item.BirthDate, 'DD/MM/YYYY')
                        : ''
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.Contact.Email">{{
                    item.Contact.Email ? item.Contact.Email : ''
                }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import ClientsApi from '~/api/clients'
import ClientHelper from 'neptune/helpers/client'
import DateMixins from 'neptune/mixins/date'
import consts from 'neptune/consts'
import _ from 'lodash'

export default {
    mixins: [DateMixins],
    props: {
        value: {
            type: [String, Object, Array],
            default: null,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        returnObject: {
            type: Boolean,
            default: false,
            required: false
        },
        dense: {
            type: Boolean,
            default: false,
            required: false
        },
        label: {
            type: String,
            default: '',
            required: false
        },
        clearable: {
            type: Boolean,
            default: false,
            required: false
        },
        icon: {
            type: String,
            default: 'mdi-account',
            required: false
        },
        prependInnerIcon: {
            type: String,
            default: null,
            required: false
        },
        autocompleteStyle: {
            type: String,
            default: '',
            required: false
        },
        externalFilters: {
            type: [Object, null],
            default: () => {
            },
            required: false
        },
        attach: {
            type: Boolean,
            default: false,
            required: false
        },
        multiple: {
            type: Boolean,
            default: false,
            required: false
        },
        chips: {
            type: Boolean,
            default: false,
            required: false
        },
        deletableChips: {
            type: Boolean,
            default: false,
            required: false
        },
        hideDetails: {
            type: Boolean,
            default: false,
            required: false
        },
        mandatory: {
            type: Boolean,
            default: false,
            required: false
        },
        readonly: {
            type: Boolean,
            default: false,
            required: false
        },
        showDetails: {
            type: Boolean,
            default: false,
            required: false
        },
        rules: {
            type: Array,
            default: () => null,
            required: false
        },
        appendIcon: {
            type: String,
            default: '',
            required: false
        },
        ignore: {
            type: Array,
            default: () => []
        },
        filled: {
            type: Boolean,
            default: () => false
        },
        solo: {
            type: Boolean,
            default: () => false
        }
    },

    data () {
        return {
            clientSearch: {
                loading: false,
                search: '',
                items: []
            },
            selectedClients: [],
            requiredRule: v => !!v || 'Ce champ est obligatoire'
        }
    },

    computed: {
        val: {
            get () { return this.value },
            set (x) { this.$emit('input', x) }
        },

        fieldLabel () {
            if (this.label) {
                return this.label
            }

            return this.multiple ? 'Sélectionnez des clients' : 'Sélectionnez un client'
        }
    },

    watch: {
        'clientSearch.search' (val) {
            ((this.clientSearch.select && val !== ClientHelper.clientFullname(this.clientSearch.select)) || !this.clientSearch.select) && this.performClientSearch(val)
        },

        value () {
            this.addCurrentValueToItemsIfNeeded()
        }
    },

    mounted () {
        this.addCurrentValueToItemsIfNeeded()
    },

    methods: {
        clientFullname: ClientHelper.clientFullname,

        performClientSearch: _.debounce(function (search) {
            this.clientSearch.loading = true
            let filters = { Search: search, Deleted: false, ClientTypes: [consts.CLIENT_TYPE_PERSON, consts.CLIENT_TYPE_ORG] }
            if (this.externalFilters) {
                filters = { ...filters, ...this.externalFilters }
            }
            ClientsApi.getPaginatedClients(filters).then(res => {
                if (this.clientSearch.search !== search) {
                    setTimeout(() => {
                        this.performClientSearch(this.clientSearch.search)
                    }, 300)
                } else {
                    const results = res.data.Clients || []
                    this.clientSearch.items = results.filter(c => !this.ignore.includes(c.ID))
                    this.clientSearch.items.push(...this.selectedClients.filter(c => !this.clientSearch.items.some(c2 => c2.ID === c.ID)))
                    this.clientSearch.loading = false
                }
            })
        }, 200),

        onChange (val) {
            if (!val) {
                this.selectedClients = []
                this.$emit('clear')
                return
            }
            const ids = Array.isArray(this.val) ? val : ((typeof val === 'object') ? [val.ID] : [val])
            this.selectedClients = this.clientSearch.items.filter(c => ids.includes(c.ID))
            this.$emit('change')
        },

        onClear (val) {
            this.$emit('clear')
        },

        async addCurrentValueToItemsIfNeeded () {
            if (!this.value) {
                return
            }

            if (this.clientSearch.items.some(r => r.ID === (this.returnObject ? this.value.ID : this.value))) {
                return
            }

            let selected
            if (!this.returnObject) {
                selected = await ClientsApi.getClient(this.value)
            } else {
                selected = this.value
            }

            this.selectedClients = [selected]
            this.clientSearch.items.push(selected)
        }

    }
}
</script>
