import './common'
import axios from 'axios'

export default {
    getModules: () => axios.get('config.modules.json', { baseURL: '/' }),

    getParams: () => axios.get('/resa-params'),
    postParams: (data) => axios.post('/resa-params', data),

    getCities: () => axios.get('/cities'),

    updateMailer: () => axios.get('/params/update-mailer'),
    updateSMSer: () => axios.get('/params/update-smser'),
    getMailerPreview: (previewType) => axios.get(`/params/mailer-preview/${previewType}`),

    getGlobalParams: () => axios.get('/global-params'),
    postGlobalParams: (data) => axios.post('/global-params', data),

    getPeriods: (params) => axios.get('/periods', { params }),
    addOrUpdatePeriod: (data) => axios.post('/periods', data),
    newPeriod: (data) => axios.post('/periods-create', data),
    deletePeriod: (id) => axios.delete(`/periods/${id}`),

    getPricings: (params) => axios.get('/pricings', { params }),
    postPricing: (data) => axios.post('/pricings', data),
    postPercentagePricing: (data) => axios.post('/pricing-percentage', data),

    getWristbandTemplates: () => axios.get('/wristband-templates'),
    getWristbandPrinterType: () => axios.get('/wristband-printer-templates'),
    upsertWristbandTemplate: (data) => axios.post('/wristband-templates', data),
    upsertWristbandPrinterType: (data) => axios.post('/wristband-printer-templates', data),
    deleteWristbandTemplate: (id) => axios.delete(`/wristband-templates/${id}`),
    deleteWristbandPrinterType: (id) => axios.delete(`/wristband-printer-templates/${id}`),
    getPaymentType: (params) => axios.get('/paymenttype', { params }),
    getEstablishments: () => axios.get('/establishments'),
    getEstablishment: (id) => axios.get(`/establishments/${id}`),
    postEstablishment: (data) => axios.post('/establishments', data),
    putEstablishment: (data) => axios.put(`/establishments/${data.ID}`, data),
    setDefaultEstablishment: (id) => axios.post(`/establishments/set-default/${id}`),

    getSuppliers: () => axios.get('/suppliers'),
    postSuppliers: (data) => axios.post('/suppliers', data),
    getTvaTypes: () => axios.get('/tva-types'),
    addOrUpdateTvaType: (data) => axios.post('/tva-types', data),
    getChartOfAccounts: () => axios.get('/chart-of-accounts'),
    getAutoDebitModes: () => axios.get('/auto-debit-modes'),
    GetAutodebitsSchedules: (data) => axios.get('/auto-debit-modes-schedules', data),
    UpsertAutodebitMode: (data) => axios.post('/auto-debit-modes', data),
    getDiscountsTemplates: () => axios.get('/discounts-templates'),

    getActionHistory: (params) => axios.get('/action-history', { params })
}
