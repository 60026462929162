export default [
    {
        path: '/clients',
        name: 'clients-index',
        meta: {
            title: 'Clients'
        },
        redirect: { name: 'clients-list' },
        component: () => import('~/views/clients/Index.vue'),
        children: [
            {
                path: 'show/:id',
                name: 'clients-show',
                meta: {
                    title: 'Clients'
                },
                component: () => import('~/views/clients/ClientsShow.vue')
            },

            {
                path: 'list',
                name: 'clients-list',
                meta: {
                    title: 'Liste des clients'
                },
                component: () => import('~/views/clients/ClientsList.vue')
            },
            {
                path: 'clubs',
                name: 'clubs',
                meta: {
                    title: 'Liste des organisations'
                },
                component: () => import('~/views/clients/Clubs.vue')
            },
            {
                path: 'planning-clubs',
                name: 'planning-clubs',
                meta: {
                    title: 'Planning des organisations'
                },
                component: () => import('~/views/clients/ClubsPlanning.vue')
            },
            {
                path: 'billing-clubs',
                name: 'billing-clubs',
                meta: {
                    title: 'Facturation des organisations'
                },
                component: () => import('~/views/clients/ClubsBilling.vue')
            },
            {
                path: 'clubs/show/:id',
                name: 'clubs-show',
                meta: {
                    title: 'Organisations'
                },
                component: () => import('~/views/clients/ClubShow.vue')
            },

            {
                path: 'clubs/new',
                name: 'clubs-new',
                meta: {
                    title: 'Création d\'une organisation'
                },
                component: () => import('~/components/clients/CreateClub.vue')
            }
        ]
    }
]
