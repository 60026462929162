<template>
    <v-card>
        <v-card-title>Fusionner deux comptes</v-card-title>
        <v-card-text>
            <div class="d-flex align-center mb-4">
                <span class="mr-2">Supprimer et/ou transférer les informations de
                    <strong>{{ clientFullname(fusionned) }}</strong> vers</span>
                <client-autocomplete
                    v-model="target" solo return-object hide-details :readonly="step >1"
                    :ignore="[fusionned.ID]" />
            </div>

            <span v-if="step === 1">
                Sélectionnez ci-dessous les informations que vous souhaitez conserver :
            </span>
            <span v-else-if="step === 2">
                Les abonnements, ventes, mandats de prélèvement et bons suivants de <strong>{{ accounts.fusionned.Firstname }} {{
                    accounts.fusionned.Lastname
                }}</strong>
                seront intégrés dans le compte de <strong>{{ accounts.target.Firstname }} {{
                    accounts.target.Lastname
                }}</strong> :
            </span>

            <v-row>
                <v-col v-for="(account, accountKey) in accounts" :key="accountKey" cols="6">
                    <v-card layout="column" class="fill-height">
                        <v-card-text
                            v-if="!account" layout="row" layout-align="center center"
                            class="grey--text fill-height text-center">
                            Utilisez le champ ci-dessus pour sélectionner un client cible dans lequel fusionner
                            les données du compte actuel.
                        </v-card-text>

                        <div v-else layout="column" class="fill-height">
                            <v-card-title>
                                {{ account.Firstname }} {{ account.Lastname }}
                                <v-spacer />
                                <v-btn v-if="step === 1" outlined @click="selectAllFrom(accountKey)">
                                    Tout cocher
                                </v-btn>
                            </v-card-title>
                            <v-card-text v-if="step === 1" layout="column" class="flex-grow-1 flex-shrink-0">
                                <div class="mb-2">
                                    <h4>Information générales</h4>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('Firstname', accountKey)"
                                        @click="selectFieldFrom('Firstname', accountKey)">
                                        {{ account.Firstname }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('Lastname', accountKey)"
                                        @click="selectFieldFrom('Lastname', accountKey)">
                                        {{ account.Lastname }}
                                    </v-btn>
                                    <v-btn
                                        v-if="account.BirthDate && !isZeroDate(account.BirthDate)"
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('BirthDate', accountKey)"
                                        @click="selectFieldFrom('BirthDate', accountKey)">
                                        {{ formatDate(account.BirthDate) }}
                                    </v-btn>
                                    <span v-else>Pas de date de naissance</span>
                                </div>

                                <div class="mb-2">
                                    <h4>Adresse:</h4>
                                    <v-btn
                                        v-if="account.Address.Address"
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('Address', accountKey)"
                                        @click="selectFieldFrom('Address', accountKey)">
                                        {{ account.Address.Address }} <br v-if="account.Address.Address">
                                        {{ account.Address.PostalCode }} {{ account.Address.City }}
                                    </v-btn>
                                    <span v-else>Aucune adresse</span>
                                </div>

                                <div class="mb-2">
                                    <h4>Contact:</h4>
                                    <v-btn
                                        v-if="account.Contact.Email"
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('Contact.Email', accountKey)"
                                        @click="selectFieldFrom('Contact.Email', accountKey)">
                                        {{ account.Contact.Email }}
                                    </v-btn>
                                    <span v-else>Aucune adresse mail</span>
                                </div>

                                <div v-if="pricingsAreNotTheSame">
                                    <h4>Tarif:</h4>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('Pricing', accountKey)"
                                        @click="selectFieldFrom('Pricing', accountKey)">
                                        {{ getPricingLabel(currentClientPricingID(account, currentPeriod.ID)) }}
                                    </v-btn>
                                </div>

                                <div class="text-caption mt-auto pt-6">
                                    Dernière mise à jour : {{ formatDateTimeHuman(account.UpdateDate) }}
                                </div>
                            </v-card-text>
                            <v-card-text v-else>
                                <div class="mb-2">
                                    <h4>Badges</h4>
                                    <span v-if="accounts[accountKey].subscriptions.length === 0">Aucun abonnement</span>
                                    <v-list two-line max-height="200" class="overflow-y-auto">
                                        <v-list-item
                                            v-for="sub in accounts[accountKey].subscriptions"
                                            :key="sub.ID">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ sub.Product ? sub.Product.Label.FR : 'Produit inconnu' }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Quantité : {{ sub.Quantity }}<span v-if="sub.SubscriptionLimitDate">, Date d'expiration :
                                                        {{ formatDate(sub.SubscriptionLimitDate) }}</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>

                                <div class="mb-2">
                                    <h4>Historique</h4>
                                    <span
                                        v-if="!accounts[accountKey].sales || accounts[accountKey].sales.length === 0">Aucune vente</span>
                                    <v-list two-line max-height="200" class="overflow-y-auto">
                                        <v-list-item
                                            v-for="sale in accounts[accountKey].sales"
                                            :key="sale.ID">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Vente {{ sale.NumDoc.NumSeq }},
                                                    {{ formatDateTimeHuman(sale.EndSale) }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="sale.lines.length > 0">
                                                    <span v-if="sale.lines.length === 1 && sale.lines[0]">{{
                                                        sale.lines[0].Label.FR
                                                    }}</span> <span
                                                        v-if="sale.lines.length > 1"> et {{ sale.lines.length - 1 }} autres articles</span>
                                                    pour {{ sale.Total }} {{ currencySymbol }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>

                                <div class="mb-2">
                                    <h4>Mandats de prélèvement</h4>
                                    <span
                                        v-if="!accounts[accountKey].SddMandate || accounts[accountKey].SddMandate.length === 0">Aucun mandat de prélèvement</span>
                                    <v-btn
                                        v-else
                                        rounded
                                        color="primary"
                                        class="text-normal ma-1"
                                        :outlined="!isFieldSelectedFor('SddMandate', accountKey)"
                                        @click="selectFieldFrom('SddMandate', accountKey)">
                                        <span
                                            v-for="(mandat, i) in accounts[accountKey].SddMandate"
                                            :key="mandat.MandateID"> {{ secureIban(mandat.IBAN) }} <span
                                                v-if="i < accounts[accountKey].SddMandate.length -1">, </span></span>
                                    </v-btn>
                                </div>

                                <div class="mb-2">
                                    <h4>Bons</h4>
                                    <span
                                        v-if="!accounts[accountKey].vouchers || accounts[accountKey].vouchers.length === 0">Aucun bon</span>
                                    <v-list two-line max-height="200" class="overflow-y-auto">
                                        <v-list-item
                                            v-for="voucher in accounts[accountKey].vouchers"
                                            :key="voucher.ID">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ voucher.Code }}, créé le {{ formatDateTimeHuman(voucher.CreationDate) }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <v-icon :color="voucher.Used ? 'red' : 'green'">
                                                        mdi-circle
                                                    </v-icon>
                                                    <span v-if="voucher.Used">
                                                        Utilisé le {{ formatDateTimeHuman(voucher.UsageDate) }}
                                                    </span>
                                                    <span v-else-if="isFuture(voucher.EndValidityDate)">
                                                        Valide jusqu'au {{ formatDate(voucher.EndValidityDate) }}
                                                    </span>
                                                    <span v-else>
                                                        Expiré le {{ formatDate(voucher.EndValidityDate) }}
                                                    </span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <v-switch v-if="hasSameSubscription" v-model="regroupSubscriptions" label="Regrouper les abonnements" />
        </v-card-text>

        <v-card-actions>
            <v-btn v-if="step == 2" text @click="step = 1">
                Retour
            </v-btn>
            <v-spacer />
            <v-btn text @click="cancel">
                Annuler
            </v-btn>
            <v-btn
                v-if="step == 1" color="primary" text :disabled="!target" :loading="loadingStep2"
                @click="goToStep2">
                Suivant
            </v-btn>
            <v-btn v-if="step == 2" color="primary" text :loading="merging" @click="validate">
                Valider
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import C from 'neptune/consts'
import ClientAutocomplete from '../ClientAutocomplete.vue'
import { date as DateMixin, client as ClientMixin, sales as SaleMixin } from 'neptune/mixins'
import ClientsApi from '~/api/clients'
import ProductsApi from '~/api/products'
import SalesApi from '~/api/sales'
import VouchersApi from '~/api/vouchers'
import { mapGetters } from 'vuex'
import _ from 'lodash'

const CLIENT_MERGE_FIELDS = {
    FIRSTNAME: 'Firstname',
    LASTNAME: 'Lastname',
    BIRTHDATE: 'BirthDate',
    CONTACT_EMAIL: 'Contact.Email',
    ADDRESS: 'Address',
    SDDMANDATE: 'SddMandate',
    PRICING: 'Pricing'
}

export default {
    components: { ClientAutocomplete },
    mixins: [DateMixin, ClientMixin, SaleMixin],
    props: {
        fusionned: {
            // was the account that was fusionned into target
            type: Object,
            required: true
        }
    },

    data: () => ({
        target: null,
        mergedFields: [],
        step: 1,
        productsByIds: [],
        clientsSales: [],
        clientsVouchers: [],
        loadingStep2: false,
        regroupSubscriptions: true,
        merging: false
    }),

    computed: {
        ...mapGetters(['currencySymbol', 'currentPeriod', 'pricings']),

        accounts () {
            return {
                fusionned: this.fusionned
                    ? Object.assign(this.fusionned, {
                        subscriptions: this.getSubscriptions(this.fusionned, this.productsByIds),
                        sales: this.preparesales(this.clientsSales.filter(s => s.Clients.includes(this.fusionned.ID))),
                        vouchers: this.clientsVouchers.filter(v => v.ClientGiftID === this.fusionned.ID)
                    })
                    : null,
                target: this.target
                    ? Object.assign(this.target, {
                        subscriptions: this.getSubscriptions(this.target, this.productsByIds),
                        sales: this.preparesales(this.clientsSales.filter(s => s.Clients.includes(this.target.ID))),
                        vouchers: this.clientsVouchers.filter(v => v.ClientGiftID === this.target.ID)
                    })
                    : null
            }
        },

        hasSameSubscription () {
            return this.fusionned && this.target &&
                this.fusionned.subscriptions.some(s1 => this.target.subscriptions.some(s2 => s1.ProductID === s2.ProductID))
        },

        pricingsAreNotTheSame () {
            return this.fusionned && this.target &&
                this.currentClientPricingID(this.fusionned, this.currentPeriod.ID) !== this.currentClientPricingID(this.target, this.currentPeriod.ID)
        }
    },

    watch: {
        target: {
            deep: true,
            handler () {
                if (this.target) {
                    this.preselectFusionnedAccountFields()
                }
            }
        },
        fusionned () {
            this.clear()
        }
    },

    methods: {
        selectFieldFrom (field, fromKey) {
            if (fromKey === 'fusionned' && !this.mergedFields.includes(field)) {
                this.mergedFields.push(field)
            } else if (fromKey !== 'fusionned' && this.mergedFields.includes(field)) {
                this.mergedFields.splice(this.mergedFields.indexOf(field), 1)
            }
        },

        getPricingLabel (pricingID) {
            const p = this.pricings ? this.pricings.find(p => p.ID === pricingID) : null
            return p ? p.Label.FR : 'Référentiel'
        },

        isFieldSelectedFor (field, fromKey) {
            return (fromKey === 'fusionned') === this.mergedFields.includes(field)
        },

        preselectFusionnedAccountFields () {
            if (!this.target) {
                return
            }
            // build mergedFields and preselect field from fusionnd object if this same fields doesnt exist on target
            this.selectAllFrom('target')
        },

        selectAllFrom (key) {
            const fromFusionned = key === 'fusionned'

            let isMerged
            for (const field of Object.values(CLIENT_MERGE_FIELDS)) {
                switch (field) {
                case 'Address':
                    isMerged = !!((fromFusionned || !this.target.Address.Address) && this.fusionned.Address.Address)
                    break
                case 'BirthDate':
                    isMerged = !!((fromFusionned || (!this.target.BirthDate || this.isZeroDate(this.target.BirthDate))) && this.fusionned.BirthDate && !this.isZeroDate(this.fusionned.BirthDate))
                    break
                default:
                    isMerged = !!((fromFusionned || !_.get(this.target, field)) && _.get(this.fusionned, field))
                }

                this.selectFieldFrom(field, isMerged ? 'fusionned' : 'target')
            }
        },

        goToStep2 () {
            this.loadingStep2 = true
            // load sales and product for subscriptions
            const salePromise = this.getClientsSales()
            const productPromise = this.getClientsProducts()
            const vouchersPromise = this.getClientsVouchers()

            Promise.all([salePromise, productPromise, vouchersPromise]).then(() => {
                this.step = 2
            }).catch((err) => {
                console.error(err)
                this.$snotify.error('Impossible de charger les données')
            }).finally(() => {
                this.loadingStep2 = false
            })
        },

        getClientsProducts () {
            let productsIDs = this.target.Cards ? this.target.Cards.map(c => c.Subscriptions ? c.Subscriptions.map(s => s.ProductID) : []).flat() : []
            productsIDs = productsIDs.concat(this.fusionned.Cards ? this.fusionned.Cards.map(c => c.Subscriptions ? c.Subscriptions.map(s => s.ProductID) : []).flat() : [])

            return new Promise((resolve, reject) => {
                ProductsApi.getProductsByIDs(productsIDs).then((res) => {
                    this.productsByIds = res.data ? res.data : []
                    resolve()
                }).catch(reject)
            })
        },

        getClientsSales () {
            return new Promise((resolve, reject) => {
                SalesApi.getSales({ Clients: [this.fusionned.ID, this.target.ID] }).then(res => {
                    this.clientsSales = res.data && res.data.Items ? res.data.Items : []
                    resolve()
                }).catch(reject)
            })
        },

        getClientsVouchers () {
            return new Promise((resolve, reject) => {
                VouchersApi.getPaginatedVouchers({
                    ClientGiftIDs: [this.fusionned.ID, this.target.ID],
                    Types: [C.VOUCHER_TYPES.GIFT_CARD, C.VOUCHER_TYPES.COLLECTABLE, C.VOUCHER_TYPES.DISCOUNT]
                }).then(res => {
                    this.clientsVouchers = res.data && res.data.Vouchers ? res.data.Vouchers : []
                    resolve()
                }).catch(reject)
            })
        },

        preparesales (sales) {
            return sales.map(s => Object.assign(s, { lines: this.getSaleLines(s) }))
        },

        secureIban (IBAN) {
            return `${IBAN.substring(0, 4)}*****${IBAN.substring(IBAN.length - 4, IBAN.length)}`
        },

        clear () {
            this.mergedFields = []
            this.step = 1
            this.clientsSales = []
            this.target = null
            this.regroupSubscriptions = true
        },

        validate () {
            this.merging = true
            ClientsApi.mergeClients({
                MergedID: this.fusionned.ID,
                TargetID: this.target.ID,
                MergedFields: this.mergedFields,
                RegroupSubscriptions: this.regroupSubscriptions
            }).then(() => {
                this.$snotify.success('Fusion effectuée')
                setTimeout(() => {
                    this.clear()
                }, 200)
                this.$emit('merged')
            }).catch((err) => {
                console.error(err)
                this.$snotify.error('Impossible de fusionner')
            }).finally(() => {
                this.merging = false
            })
        },

        cancel () {
            setTimeout(() => {
                this.clear()
            }, 200)
            this.$emit('cancel')
        }
    }
}
</script>

<style scoped>
.text-normal {
    text-transform: none !important;
}
</style>
