export default [
    {
        path: '/',
        name: 'home',
        redirect: '/login',
        meta: {
            title: 'Accueil'
        },
        component: () => import('~/views/Home.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Connexion',
            disableDashboardLayout: true
        },
        component: () => import('~/views/Login.vue')
    },
    {
        path: '/renew-password/:token',
        name: 'renew-password',
        meta: {
            title: 'Renouvellement du mot de passe',
            disableDashboardLayout: true
        },
        component: () => import('~/views/RenewPassword.vue')
    },
    {
        path: '/autologin/:autologin',
        name: 'autologin',
        meta: {
            title: 'Auto-connexion',
            disableDashboardLayout: true
        },
        component: () => import('~/views/Login.vue')
    },
    {
        path: '/profil',
        name: 'profil',
        meta: {
            title: 'Profil'
        },
        component: () => import('~/views/profil/Index.vue')
    }
]
