import ApiParams from '~/api/params'
import moment from 'moment'
import eventbus from '~/eventbus'
import consts from 'neptune/consts'
import _ from 'lodash'
import paramsMixin from 'neptune/helpers/params'

const initialState = () => ({
    modules: {
        planning: false,
        ctm: false,
        clients: false,
        spa: false,
        stats: false,
        indoorpos: false
    },
    params: null,
    formattedParams: paramsMixin.defaultParams(),
    globalParams: null,

    periods: [],
    pricings: [],
    tva: [],
    chartOfAccounts: [],
    suppliers: [],
    paymentType: [],
    establishments: [],
    wristband: [],
    wristbandPrinterTypes: [],
    selectedEstablishments: [],
    autodebitModes: [],
    discountsTemplates: []

})

export default {
    state: initialState,
    getters: {
        modules: state => state.modules,
        params: state => state.params,
        cities: state => state.cities,
        formattedParams: state => state.formattedParams,
        globalParams: state => state.globalParams,
        periods: state => state.periods,
        currentPeriod: function (state) {
            if (state.periods) {
                let current = state.periods.find(p => moment().isBetween(moment(p.StartDate), moment(p.EndDate)))
                if (current === undefined) {
                    current = state.periods[state.periods.length - 1]
                }
                return current
            }
            return {}
        },
        pricing: state => state.pricings, // deprecated
        pricings: state => state.pricings,
        tva: state => state.tva,
        chartOfAccounts: state => state.chartOfAccounts,
        discountsTemplates: state => state.discountsTemplates,
        suppliers: state => state.suppliers,
        autodebitModes: state => state.autodebitModes,
        paymentType: state => state.paymentType,
        establishments: state => state.establishments,
        wristband: state => state.wristband,
        wristbandPrinterTypes: state => state.wristbandPrinterTypes,
        establishmentsById: state => _.keyBy(state.establishments, 'ID'),
        defaultEstablishment: state => state.establishments.find(e => e.IsDefault) || null,
        selectedEstablishments: state => state.selectedEstablishments,
        statsSeason: state => {
            const season = (state.formattedParams && state.formattedParams.statsSeason) ? state.formattedParams.statsSeason : { start: '01/01', end: '31/12' }
            const start = moment(season.start, 'DD/MM')
            const end = moment(season.end, 'DD/MM')
            return {
                start: {
                    day: start.format('D'),
                    month: start.format('M')
                },
                end: {
                    day: end.format('D'),
                    month: end.format('M')
                }
            }
        },
        currencySymbol: state => {
            if (!state.globalParams[consts.GLOBAL_PARAMS.PARAM_CURRENCY_SIGN]) {
                return '?'
            } else if (!state.globalParams[consts.GLOBAL_PARAMS.PARAM_CURRENCY_SIGN].Value) {
                return state.globalParams[consts.GLOBAL_PARAMS.PARAM_CURRENCY_SIGN].DefaultValue
            }
            return state.globalParams[consts.GLOBAL_PARAMS.PARAM_CURRENCY_SIGN].Value
        },

        creditLabel: state => {
            if (!state.globalParams[consts.GLOBAL_PARAMS.PARAM_CREDIT_LABEL]) {
                return 'cagnotte'
            } else if (!state.globalParams[consts.GLOBAL_PARAMS.PARAM_CREDIT_LABEL].Value) {
                return state.globalParams[consts.GLOBAL_PARAMS.PARAM_CREDIT_LABEL].DefaultValue
            }
            return state.globalParams[consts.GLOBAL_PARAMS.PARAM_CREDIT_LABEL].Value
        },

        unitsLabel: state => {
            if (!state.globalParams[consts.GLOBAL_PARAMS.PARAM_UNITS_LABEL]) {
                return 'unités'
            } else if (!state.globalParams[consts.GLOBAL_PARAMS.PARAM_UNITS_LABEL].Value) {
                return state.globalParams[consts.GLOBAL_PARAMS.PARAM_UNITS_LABEL].DefaultValue
            }
            return state.globalParams[consts.GLOBAL_PARAMS.PARAM_UNITS_LABEL].Value
        }
    },
    actions: {
        getModules ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getModules().then(res => {
                    commit('set_modules', res.data || {})
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getParams ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getParams().then(res => {
                    commit('set_params', res.data || [])
                    commit('set_formatted_params', paramsMixin.formatParams(res.data || []))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getCities ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getCities().then(res => {
                    commit('set_cities', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getGlobalParams ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getGlobalParams().then(res => {
                    const params = paramsMixin.formatGlobalParams(res.data)
                    commit('set_global_params', params || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPeriods ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getPeriods().then(res => {
                    let periods = []
                    if (res.data) {
                        periods = res.data.filter(p => p.Label && p.Label.FR)
                    }
                    commit('set_periods', periods)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPricings ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getPricings().then(res => {
                    commit('set_pricings', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getAutoDebitModes ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getAutoDebitModes().then(res => {
                    commit('set_auto_debit_mode', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getWristband ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getWristbandTemplates().then(res => {
                    commit('set_wristband', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getWristbandPrinter ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getWristbandPrinterType().then(res => {
                    commit('set_wristband_printer', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getChartOfAccounts ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getChartOfAccounts().then(res => {
                    commit('set_chart_of_accounts', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getDiscountsTemplates ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getDiscountsTemplates().then(res => {
                    commit('set_discounts_templates', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getSuppliers ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getSuppliers().then(res => {
                    commit('set_suppliers', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getTvaTypes ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getTvaTypes().then(res => {
                    commit('set_tva', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getPaymentType ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getPaymentType().then(res => {
                    commit('set_paymentType', res.data || [])
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getEstablishments ({ commit }) {
            return new Promise((resolve, reject) => {
                ApiParams.getEstablishments().then(res => {
                    commit('set_establishments', res.data || [])
                    // By default select no one etab (so no etab condition in filter : so all data are display for all etabs)
                    //                    commit('set_selectedEstablishments', [])
                    // Depend of the etabs (comment or not those lines)
                    // if (this.state.params.selectedEstablishments.length === 0) { // Select At Least One Establishment
                    //     commit('add_selectedEstablishments', res.data[0].Label || []) // by default the first one
                    // }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getSelectAllEstablishments ({ commit }) {
            commit('set_selectedEstablishments', []) // on vide avant au cas où
            const establishments = JSON.parse(JSON.stringify(this.state.params.establishments))
            establishments.forEach(establishment => {
                commit('add_selectedEstablishments', establishment.ID)
            })
        }
    },

    mutations: {
        reset_params (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
        },

        set_modules (state, val) {
            state.modules = val
        },

        set_params (state, val) {
            state.params = val
        },

        set_cities (state, val) {
            state.cities = val
        },

        set_formatted_params (state, val) {
            state.formattedParams = val
        },

        set_global_params (state, val) {
            state.globalParams = val
        },

        set_periods (state, val) {
            state.periods = val.sort((a, b) => moment(a.EndDate).diff(moment(b.EndDate)))
        },

        set_pricings (state, val) {
            state.pricings = val
        },

        set_auto_debit_mode (state, val) {
            state.autodebitModes = val
        },

        set_discounts_templates (state, val) {
            state.discountsTemplates = val
        },

        set_suppliers (state, val) {
            state.suppliers = val
        },

        set_tva (state, val) {
            state.tva = val
        },

        set_chart_of_accounts (state, val) {
            state.chartOfAccounts = val
        },

        set_wristband (state, val) {
            state.wristband = val
        },

        set_wristband_printer (state, val) {
            state.wristbandPrinterTypes = val
        },

        set_paymentType (state, val) {
            state.paymentType = val
        },

        set_establishments (state, val) {
            state.establishments = val
        },

        upsert_establishment (state, val) {
            const index = state.establishments.findIndex(e => e.ID === val.ID)
            if (index === -1) {
                state.establishments.push(val)
            } else {
                state.establishments[index] = val
            }
        },

        set_selectedEstablishments (state, val) {
            const enabledEstablishments = state.establishments.filter(e => e.Status)
            if (enabledEstablishments.length < 2) { // Select nothing if there are only one enabled establishment
                state.selectedEstablishments = []
            } else {
                state.selectedEstablishments = val
            }
            eventbus.$emit('establishments-selected', true)
        },

        add_selectedEstablishments (state, val) {
            state.selectedEstablishments.push(val)
        }
    }
}
