const fileTypes = [
    { label: "PDF", mimeTypes: ["application/pdf"], value: "pdf", icon: "mdi-file-pdf-box", color: 'red' },
    { label: "Image", mimeTypes:['image/jpeg', 'image/jpg', 'image/png'], value: "png", icon: "mdi-image", color: 'blue' },
    { label: "Document Word", mimeTypes:['application/msword', '.doc', '.docx', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"], value: "docx", icon: "mdi-file-word", color: 'blue' },

]

const fileDocumentTypes = [
    { text: "Contrat d'abonnement", value: "subscription-contract", acceptedFileTypes: ['.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'] },
]

export { fileTypes, fileDocumentTypes }

export default {
    getFileType(mimeType){
        return fileTypes.find(t => t.mimeTypes.includes(mimeType)) || { label: "Inconnu", mimeTypes: "", value: "unknow", icon: "mdi-file", color: 'grey' }
    },

    getFileDocumentType(type){
        return fileDocumentTypes.find(t => t.value===type) || { label: "Autre", value: null }
    }
}