import './common'
import axios from 'axios'

export default {
    login: user => axios.post('/auth/login', user),
    loginToken: token => axios.post('/auth/login-token', { Token: token }),
    logout: () => axios.post('/auth/logout'),
    getAuthToken: data => axios.post('/auth-cas/token', data),
    checkAuthToken: (token) => axios.get(`/auth-cas/token/${token}`),
    lostPassword: (payload) => axios.post('/auth/lost-password', payload),
    getRenewPasswordToken: (token) => axios.get(`/auth/renew-password-token/${token}`),
    renewPassword: (payload) => axios.post('/auth/renew-password', payload)
}
