import axios from 'axios';
import { h } from 'vue';

export default {
    searchAddressAPI(searchStr, type='street') {
        return new Promise((resolve,reject) => {
             let url = `https://api-adresse.data.gouv.fr/search?autocomplete=1&q=${searchStr}`
             if (type) {
                url += `&type=${type}`
             }
             
             axios.create().get(url).then((response) => {
                resolve (response.data.features.map((feature) => {
                     return {
                        Address: feature.properties.name,
                        City: feature.properties.city,
                        PostalCode: feature.properties.postcode,
                        Streetnum : feature.properties.housenumber,
                        _context: feature.properties.context,
                    }
                }))
          }).catch((err) => {
                reject(err)
            })
        })
    },

    getFullAddress(address) {
        // address in an object with Address, City, PostalCode
        // returns a string with the full address, depending non empty fields
        let fullAddress = ''
        if (address.Address) {
            fullAddress += address.Address
        }

        if (address.PostalCode) {
            fullAddress += ', ' + address.PostalCode
        }

        if (address.City) {
            fullAddress += ' ' + address.City
        }
    }
}
