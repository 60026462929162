import clientHelper from '../helpers/client'
import ui, { 
    clientsGenders,
    clientFields,
    orgTypes
} from '../ui/client'

export default {
    data: () => ({
        clientsGenders,
        clientFields,
        orgTypes
    }),

    methods: {
        ...clientHelper,
        ...ui,
    }
}
