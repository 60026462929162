import { groupExitModes, renewModeBalanceTypes, renewModeValiditytTypes } from "../ui/products";
import consts from "../consts";
import moment from "moment";

export default {
  renewModeBalanceType(x) {
    return renewModeBalanceTypes.find((t) => t.value == x);
  },
  renewModeValiditytType(x) {
    return renewModeValiditytTypes.find((t) => t.value == x);
  },

  groupExitMode(x) {
    return groupExitModes.find((t) => t.value == x);
  },

  getSubscriptionLimitDate(startDate, product) {
    let limitDate = null;
    if (product.Subscription) {
      if (product.Subscription.Type === consts.SUBSCRIPTION_TYPE_SUBSCRIPTION) {
        switch (product.Subscription.DurationType) {
          case consts.DURATION_PER_DAY:
            limitDate = moment(startDate).add(product.Subscription.Duration, "d");
            break;
          case consts.DURATION_PER_MONTH:
            limitDate = moment(startDate).add(product.Subscription.Duration, "M");
            break;
          case consts.DURATION_PER_YEAR:
            limitDate = moment(startDate).add(product.Subscription.Duration, "y");
            break;
        }
      } else {
        limitDate = moment(startDate).add(product.Subscription.ValidityDuration, "d");
      }
      if (!limitDate || moment(product.Subscription.EndValidityDate).isBefore(limitDate)) {
        limitDate = moment(product.Subscription.EndValidityDate);
      }
      return limitDate.format();
    } else {
      return null;
    }
  },

  isProductInStockAtDateUsingProductsStockConsumption(productID, date, productsStockConsumption) {
    const outOfStockDate = this.getProductOutOfStockDateFromProductsStockConsumption(productID, productsStockConsumption)
    if (outOfStockDate === true) {
      return true
    } else if (!outOfStockDate) {
      return false
    }

    return moment(date).isAfter(outOfStockDate)
  },

  getProductOutOfStockDateFromProductsStockConsumption(productID, productsStockConsumption) {
    if (!productsStockConsumption || !productsStockConsumption[productID] || !productsStockConsumption[productID].Consumptions) {
      return null
    }

    for (const consumption of productsStockConsumption[productID].Consumptions) {
      if (consumption.RemainingStock <= 0) {
        return consumption.Date
      }
    }

    return true
  },

  isEstablishmentStockEmpty(establishmentID, product, takenQuantity = 1, packedProducts) {
    if (!product) {
      return false
    }

    if (!takenQuantity) {
      takenQuantity = 1 // Stock is empty if we cannot take one more
    }

    if (product.Stock !== -1 && product.StocksByEstablishment) {
      const stockInfo = product.StocksByEstablishment.find((info) => info.EstablishmentID === establishmentID)
      if (stockInfo && (stockInfo.Stock - takenQuantity) < 0) {
        return true
      }
    }

    if (product.PackedProducts) {
      for (const prod of product.PackedProducts) {
        const productInPack = packedProducts.find(p => p.ID === prod.ProductID)
        if (!productInPack) {
          console.error('Product in pack not found', prod.ProductID)
          return true
        }

        const totalQuantity = prod.Amount * takenQuantity
        if (product && this.isEstablishmentStockEmpty(establishmentID, productInPack, totalQuantity, packedProducts)) {
          return true
        }
      }
    }

    return false
  },

  isEstablishmentStockBlocked(establishmentID, product, takenQuantity, packedProducts) {
    if (!product) {
      return false
    }

    if (!takenQuantity) {
      takenQuantity = 1  // Stock is blocked if we cannot take one more without going under min
    }

    if (product.Stock !== -1 && product.StocksByEstablishment) {
      const stockInfo = product.StocksByEstablishment.find((info) => info.EstablishmentID === establishmentID)
      if (stockInfo && (stockInfo.Stock - takenQuantity) < stockInfo.StockMinForSale) {
        return true
      }
    }

    if (product.PackedProducts) {
      for (const prod of product.PackedProducts) {
        const productInPack = packedProducts.find(p => p.ID === prod.ProductID)
        if (!productInPack) {
          console.error('Product in pack not found', prod.ProductID)
          return true
        }
        const totalQuantity = prod.Amount * takenQuantity
        if (product && this.isEstablishmentStockBlocked(establishmentID, productInPack, totalQuantity, packedProducts)) {
          return true
        }
      }
    }

    return false
  },

  getEstablishmentProductStockInfo(establishmentID, product) {
    return product && product.StocksByEstablishment && product.StocksByEstablishment.find((info) => info.EstablishmentID === establishmentID)
  },

  getEstablishmentProductStock(establishmentID, product) {
    if (!product) {
      return 0
    }
    if (product.Stock === -1 || !product.StocksByEstablishment) {
      return -1
    }
    const stockInfo = product.StocksByEstablishment.find((info) => info.EstablishmentID === establishmentID)
    return stockInfo ? stockInfo.Stock : 0
  },

  isProductAdjustable (product) {
      return product && product.Subscription && product.Subscription.Adjustable
  }
};
