import Consts from '../consts'

const clientsGenders = [
    { text: 'M', value: Consts.CLIENTS.GENDERS.MALE, civilite: 'Monsieur', sex: "Homme", icon: 'mdi-gender-male' },
    { text: 'Mme', value: Consts.CLIENTS.GENDERS.FEMALE, civilite: 'Madame', sex: "Femme", icon: 'mdi-gender-female' },
    // { text: '', value: Consts.CLIENTS.GENDERS.UNKNOW , civilite: 'Non précisé', sex: "Non précisé", icon: 'mdi-gender-non-binary' }
]

const clientFields = [
    { text: 'Prénom', value: 'Firstname' },
    { text: 'Nom', value: 'Lastname' },
    { text: 'Adresse', value: 'Address.Address' },
    { text: 'Code postal', value: 'Address.PostalCode' },
    { text: 'Ville', value: 'Address.City' },
    { text: 'Date de naissance', value: 'BirthDate' },
    { text: 'Téléphone fixe', value: 'Contact.Phone' },
    { text: 'Téléphone mobile', value: 'Contact.Mobile' },
    { text: 'E-mail', value: 'Contact.Email' }
]

const orgTypes = [
    { text: 'Société', value: 1 },
    { text: 'Association', value: 2 },
    { text: 'École', value: 3 },
    { text: 'CE', value: 4 },
    { text: 'Collectivité', value: Consts.CLIENT_ORG_COLLECTIVITY },
    { text: 'Groupement', value: Consts.CLIENT_ORG_GROUPING },
    { text: 'Organisation', value: Consts.CLIENT_ORG_ORGANIZATION },
    { text: 'Particulier', value: Consts.CLIENT_ORG_INDIVIDUAL },
    { text: 'Privé', value: Consts.CLIENT_ORG_PRIVATE },
    { text: 'Établissement public', value: Consts.CLIENT_ORG_PUBLIC }
]

// - L'adresse (rue/n°)
// - Le code postal
// - La ville
// - L'adresse e-mail
// - La date de naissance
// - Le numéro de téléphone fixe
// - Le numéro de téléphone mobile

export default {
    /**
     * @param {string} x Gender code : 'M', 'F', etc.
     */
    clientsGender(x) {
        return clientsGenders.find(elem => elem.value == x)
    },

    orgType(x){
        return orgTypes.find(elem => elem.value == x)
    }
}

export {
    clientsGenders,
    clientFields,
    orgTypes
}