import './common'
import axios from 'axios'

export default {
    getClientAccounts: (clientId, params) => axios.get(`/clients/${clientId}/accounts`, { params }),
    postClientCreateAccount: (clientId, data) => axios.post(`/clients/${clientId}/create-account`, data),
    postClientDisableAccount: (clientId, data) => axios.post(`/clients/${clientId}/disable-account`, data),
    patchClientOnlineUser: (clientId, data) => axios.patch(`/clients/${clientId}/online-user`, data),
    postClientEnableAccount: (clientId, data) => axios.post(`/clients/${clientId}/enable-account`, data),
    postClientPermissions: (clientId, data) => axios.post(`/clients/${clientId}/permissions`, data),

    getTrainers: () => axios.get('/trainers'),
    getSellers: (params) => axios.get('/sellers', { params }),
    getSellersProfils: (params) => axios.get('/sellers-profils', { params }),
    postSeller: (data) => axios.post('/sellers', data),
    putSeller: (data) => axios.put(`/sellers/${data.ID}`, data),

    updateAccountPassword: (accountId, data) => axios.put(`/accounts/${accountId}/change-password`, data),

    getPermissionsProfiles: () => axios.get('/profiles'),
    createPermissionsProfile: (data) => axios.post('/profiles', data),
    updatePermissionsProfile: (data) => axios.put(`/profiles/${data.ID}`, data)
}
